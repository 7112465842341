import { Filter } from '@carbon/icons-react';
import { useQuery } from '@tanstack/react-query';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  CurrencyFlag,
  PageHead,
  PaginatorTemplate,
  Status,
  TableSearch,
} from '../../../../components';
import { Translate, translateWithValues } from '../../../../i18n/translate';
import { Download } from '../../../../icons';
import { PageHeadContext } from '../../../../providers/PageHead';
import PaymentService from '../../paymentService';

const Dashboard = () => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [globalSearch, setGlobalSearch] = useState('');
  const [activeFilterCount] = useState(0);
  const [selectedRates, setSelectedRates] = useState<RateRequest[] | null>(null);
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const navigate = useNavigate();
  const rateRequestQuery = useQuery({
    queryKey: ['rate_requests_query'],
    queryFn: async () => {
      const data = await PaymentService.getRateRequests();
      setTotalRecords(data.length);
      return data;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setPageHeadData({
      title: 'payment_requests.title',
      description: 'payment_requests.description',
    });
  }, []);

  const header = (
    <div className='flex flex-col md:flex-row gap-3 justify-between'>
      <div className='text-neutral-1 text-lg-semibold'>
        <Translate value='total_rate_requests' />
        <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
          {totalRecords}
        </span>
      </div>
      <div className='flex justify-around gap-2 items-center'>
        <TableSearch
          globalFilterValue={globalSearch}
          onGlobalFilterChange={(e) => {
            setGlobalSearch(e.target.value);
          }}
        />
        <div className={`ml-2 relative ${activeFilterCount ? 'mr-1' : ''}`}>
          <Button severity='secondary' className='p-button-text h-12'>
            <Filter size={20} />
            <Translate value='filterLabel' className='ml-1 hidden lg:block' />
          </Button>
          {!!activeFilterCount && (
            <Badge value={activeFilterCount} className='bg-primary absolute -top-2 -right-2' />
          )}
        </div>
        <div>
          <Button severity='contrast' className='h-12'>
            <Download /> <Translate value='downloadLabel' className='ml-1 hidden lg:block' />
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <div className='p-8 bg-white rounded-c8 border border-neutral-surface-dark'>
      <div className='flex justify-between items-center gap-4'>
        <PageHead title='payment_table.title' description='payment_table.description' />
        <div>
          <Button
            severity='info'
            onClick={() => {
              navigate('/payment/rate-requests/add');
            }}
          >
            <Translate value='request_new_quote' />
          </Button>
        </div>
      </div>
      <DataTable
        className='mt-5'
        header={header}
        selectAll={true}
        selectionMode={'checkbox'}
        selection={selectedRates!}
        onSelectionChange={(e: { value: RateRequest[] }) => setSelectedRates(e.value)}
        dataKey='paymentId'
        first={0}
        rows={10}
        value={rateRequestQuery.data}
        paginator={true}
        paginatorTemplate={PaginatorTemplate}
        totalRecords={totalRecords}
      >
        <Column selectionMode='multiple' headerStyle={{ width: '1rem' }}></Column>
        <Column field='paymentId' header={translateWithValues('payment_id')} />
        <Column
          field='buyCurrency'
          header={translateWithValues('buy_currency')}
          body={(rateRequest: RateRequest) => (
            <div className='text-sm-medium text-neutral-2'>
              <CurrencyFlag currencyCode={rateRequest.buyCurrency} />
            </div>
          )}
        />
        <Column field='buyAmount' header={translateWithValues('buy_amount')} />
        <Column
          field='sellCurrency'
          header={translateWithValues('sell_currency')}
          body={(rateRequest: RateRequest) => (
            <div className='text-sm-medium text-neutral-2'>
              <CurrencyFlag currencyCode={rateRequest.sellCurrency} />
            </div>
          )}
        />
        <Column field='valueDate' header={translateWithValues('Value Date')} />
        <Column
          field='status'
          header={translateWithValues('status')}
          body={(rateRequest: RateRequest) => <Status status={rateRequest.status} />}
        />
      </DataTable>
      <div className='flex justify-center gap-3 pt-6'>
        <Button severity='contrast'>
          <Translate value={'reject_select_quotas'} />
        </Button>
        <Button severity='info'>
          <Translate value={'accept_select_rates'} />
        </Button>
        <Button severity='contrast'>
          <Translate value={'send_select_quotas'} />
        </Button>
      </div>
    </div>
  );
};

export default Dashboard;
