import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { DropdownInput } from '../../../../components';
import { Translate } from '../../../../i18n/translate';
import { AddAlt } from '../../../../icons';
import { useAppDispatch } from '../../../../store';
import { selectCurrentCustomer, sendToast } from '../../../../store/slices/main/mainSlice';
import { CustomerType } from '../../../../types';
import ClientService from '../../../Client/clientService';
import PaymentService from '../../paymentService';
import CustomerCreationModal from './CustomerCreationModal';

interface CustomerDetailsProps {
  onSaveAndProceed?: (id: string) => void;
  quoteId: string;
}

interface Option {
  value: string;
  label: string;
}

export const CustomerDetails = ({ onSaveAndProceed, quoteId }: CustomerDetailsProps) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, errors },
  } = useForm<CustomerDetails>();
  console.log(quoteId);
  const [options, setOptions] = useState<Option[]>([]);
  const dispatch = useAppDispatch();
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNewCustomer, setIsNewCustomer] = useState(false);

  const getCustomers = async () => {
    const customerId = selectedCustomer?.id;
    if (customerId) {
      const payload = {
        customerType: [CustomerType.ORDERING_CUSTOMER],
      };
      const response = await ClientService.getChildClients(
        customerId,
        0,
        0,
        payload,
        '',
        null,
        true,
      );
      if (response?.content) {
        const customerOptions = response.content.map(({ id, customerShortName }) => ({
          value: `${id}`,
          label: customerShortName,
        }));
        setOptions(customerOptions);
      }
    }
  };

  const handleNewCustomerCreation = (customer: Client) => {
    const newCustomerOption = {
      value: `${customer.id}`,
      label: customer.customerShortName,
    };
    setOptions([...options, newCustomerOption]);
    reset({ selectedCustomer: `${customer.id}` });
    setIsNewCustomer(true);
  };

  const handleDeleteEntry = () => {
    if (isNewCustomer) {
      reset();
    }
    setIsNewCustomer(false);
  };

  useEffect(() => {
    getCustomers();
  }, [selectedCustomer]);

  const saveCustomerDetails = async (formData: CustomerDetails) => {
    const payload = {
      quoteId,
      remitterCustomerId: formData.selectedCustomer,
    };
    try {
      const response = await PaymentService.createPayment(payload);
      showToast('success', 'success', 'quote_saved.message.success');
      if (onSaveAndProceed) {
        onSaveAndProceed(formData.selectedCustomer);
      }
      console.log(response);
    } catch {
      showToast('error', 'error', 'quote_cant_be_Saved');
    }
  };

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const showErrorToast = () => {
    showToast('error', 'client.field.validation.error', 'client.field.validation.error.detail');
  };

  return (
    <div className='relative'>
      {!quoteId ? (
        <div className='absolute inset-0 bg-white opacity-70 cursor-not-allowed z-10' />
      ) : (
        ''
      )}
      <div className='rounded-c8 shadow-c bg-white p-8'>
        <CustomerCreationModal
          visible={isModalVisible}
          setVisible={setIsModalVisible}
          handleNewCustomer={handleNewCustomerCreation}
          handleDeleteEntry={handleDeleteEntry}
        />
        <div className='flex justify-between flex-wrap'>
          <div>
            <div className='text-sm-bold'>
              <Translate value='customer.title' />
            </div>
            <div className='text-neutral-3'>
              <Translate value='customer.description' />
            </div>
          </div>
        </div>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(saveCustomerDetails)}>
          <div className='mt-8 flex gap-10 text-neutral-2 text-sm-medium'>
            <DropdownInput
              disabled={isNewCustomer || !quoteId}
              className='min-w-[250px]'
              placeholder='change_customer'
              options={options}
              label='select_existing_customer'
              isRequired
              name='selectedCustomer'
              filter
              control={control}
              error={errors['selectedCustomer']}
              rules={{ required: true }}
            />
            <div className='self-end'>
              <Button
                severity='contrast'
                className='h-[43px] w-60'
                onClick={() => {
                  setIsModalVisible(true);
                }}
                type='button'
              >
                <AddAlt />
                <Translate value='add_new_customer.header' className='ml-1' />
              </Button>
            </div>
          </div>
          <div className='flex items-center justify-center mt-10 gap-5'>
            <Button
              type='submit'
              severity='info'
              className='w-48 inline-flex items-center justify-center'
              disabled={!quoteId}
              onClick={() => {
                if (!isValid) {
                  showErrorToast();
                  return;
                }
              }}
            >
              <Translate value='save_and_proceed' />
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
