import { useMutation } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CurrencySelect, PageHead } from '../../components';
import QuoteAmountInput from '../../components/QuoteAmount';
import useRole from '../../hooks/useRoles';
import { Translate, translateWithValues } from '../../i18n/translate';
import SwitchOutlined from '../../icons/SwitchOutlined';
import { PageHeadContext } from '../../providers/PageHead';
import { useAppDispatch } from '../../store';
import { selectLoading, sendToast, setQuickQuote } from '../../store/slices/main/mainSlice';
import { selectCustomerCurrencies } from '../../store/slices/refdata/refdataSlice';
import PaymentService from '../Payment/paymentService';

const Dashboard = () => {
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
  } = useForm<any>();

  const buyAmountValue = watch('buyAmount');
  const sellAmountValue = watch('sellAmount');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currencies = useSelector(selectCustomerCurrencies);
  const [buyCurrency, setBuyCurrency] = useState<CurrencyConfiguration>();
  const [sellCurrency, setSellCurrency] = useState<CurrencyConfiguration>();

  const buyCurrencyValue = watch('buyCurrency');
  const sellCurrencyValue = watch('sellCurrency');
  const isLoading = useSelector(selectLoading);
  const { hasRole } = useRole();

  useEffect(() => {
    setBuyCurrency(
      currencies.find((val) => {
        return buyCurrencyValue == val.isocode;
      }),
    );
  }, [buyCurrencyValue]);

  useEffect(() => {
    setSellCurrency(
      currencies.find((val) => {
        return sellCurrencyValue == val.isocode;
      }),
    );
  }, [sellCurrencyValue]);

  useEffect(() => {
    if (buyCurrency && buyAmountValue) {
      setValue('buyAmount', parseFloat(buyAmountValue).toFixed(buyCurrency.amountPrecision));
    }
  }, [buyCurrency]);

  useEffect(() => {
    if (sellCurrency && sellAmountValue) {
      setValue('sellAmount', parseFloat(sellAmountValue).toFixed(sellCurrency.amountPrecision));
    }
  }, [sellCurrency]);

  useEffect(() => {
    setPageHeadData({
      title: 'dashboard',
      description: 'dashboard.description',
      className: 'pb-[16px] pr-4',
    });
  }, [setPageHeadData]);

  const handleAmountChange = (field: 'buyAmount' | 'sellAmount') => {
    if (field === 'buyAmount' && buyAmountValue) setValue('sellAmount', '');
    else if (field === 'sellAmount' && sellAmountValue) setValue('buyAmount', '');
  };

  const handleDecimalInput = (e: React.KeyboardEvent<HTMLInputElement>, precesion?: number) => {
    const input = e.currentTarget.value;
    const key = e.key;
    if (['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(key)) {
      return;
    }
    if (!/^[0-9.]$/.test(key)) {
      e.preventDefault();
    }

    if (key === '.' && input.includes('.')) {
      e.preventDefault();
    }

    if (key === '.' && precesion === 0) {
      e.preventDefault();
    }

    const [, decimals] = input.split('.');
    if (decimals && precesion && decimals.length >= precesion) {
      e.preventDefault();
    }
  };

  const saveQuickQuote = (data: any) => {
    const newData: any = {
      sellCurrency: data.sellCurrency,
      buyCurrency: data.buyCurrency,
      sellAmount: data.sellAmount ? data.sellAmount : undefined,
      buyAmount: data.buyAmount ? data.buyAmount : undefined,
    };
    getQuickQuoteMutation.mutate(newData);
  };

  const getQuickQuoteMutation = useMutation({
    mutationFn: async (newData: any) => {
      const response: QuickQouteProps | 'Error' = await PaymentService.getQuickQuote(newData);
      if (response !== 'Error') {
        dispatch(setQuickQuote(response));
        navigate('payment/rate-requests/edit');
      } else {
        dispatch(
          sendToast({ severity: 'error', summary: 'failed', detail: 'createQuickQuoteError' }),
        );
      }
    },
  });

  const handleCurrencySwitch = () => {
    const { buyAmount, sellAmount } = getValues();
    setValue('buyAmount', sellAmount || '');
    setValue('sellAmount', buyAmount || '');
  };

  const showErrorToast = () => {
    if (
      errors['buyAmount']?.type === 'max' ||
      errors['buyAmount']?.type === 'min' ||
      errors['sellAmount']?.type === 'max' ||
      errors['sellAmount']?.type === 'min'
    )
      showToast('error', 'quickQuoteValidationErrorTitle', 'quickQuoteValidationErrorDesc');
    else
      showToast('error', 'client.field.validation.error', 'client.field.validation.error.detail');
  };

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  if (!hasRole('PAYMENT_CREATE')) {
    return <></>;
  }

  return (
    <div className='pl-[49px] pr-[57px]'>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(saveQuickQuote)}>
        <div className='card w-full lg:w-2/4 mt-8 p-8'>
          <PageHead title='quickQuote' description='quickQuoteDesc'></PageHead>
          <div className='flex flex-col gap-4 bg-primary-surface-light p-8 rounded-c8 text-neutral-2 text-sm-medium'>
            <div className='flex gap-2'>
              <CurrencySelect
                label='buy_currency'
                placeholder='currency'
                name='buyCurrency'
                error={errors['buyCurrency']}
                isRequired
                control={control}
                rules={{ required: true }}
                currencySource='buy'
                includeAll={false}
              />
              <QuoteAmountInput
                formRegister={register('buyAmount', {
                  required: !sellAmountValue,
                  onChange: () => handleAmountChange('buyAmount'),
                  min: {
                    value: buyCurrency?.minAmount?.value || 0,
                    message: translateWithValues('sellAmountMin', {
                      minAmount: buyCurrency?.minAmount?.value + ' ' + buyCurrency?.isocode,
                    }),
                  },
                  ...(buyCurrency?.maxAmount?.value &&
                    buyCurrency?.maxAmount?.value > 0 && {
                      max: {
                        value: buyCurrency.maxAmount.value,
                        message: translateWithValues('sellAmountMax', {
                          maxAmount: buyCurrency?.maxAmount.value + ' ' + buyCurrency?.isocode,
                        }),
                      },
                    }),
                  pattern: {
                    value: RegExp(
                      `^\\d+(\\.\\d{${buyCurrency?.amountPrecision && buyCurrency?.amountPrecision > 0 ? 1 : 0},${buyCurrency?.amountPrecision}})?$`,
                    ),
                    message: translateWithValues('amountValidation', {
                      validationValue: buyCurrency?.amountPrecision,
                    }),
                  },
                })}
                error={errors.buyAmount}
                label='buyAmount'
                placeholder='amount'
                className='w-full'
                isRequired={!sellAmountValue}
                isEditMode={true}
                disabled={Boolean(sellAmountValue)}
                value={buyAmountValue}
                onKeyDown={(e) => handleDecimalInput(e, buyCurrency?.amountPrecision)}
              />
            </div>
            <div className='self-end'>
              <div className='cursor-pointer' onClick={handleCurrencySwitch}>
                <SwitchOutlined />
              </div>
            </div>
            <div className='flex gap-2'>
              <CurrencySelect
                label='sell_currency'
                placeholder='currency'
                name='sellCurrency'
                error={errors['sellCurrency']}
                isRequired
                control={control}
                rules={{ required: true }}
                currencySource='sell'
              />
              <QuoteAmountInput
                formRegister={register('sellAmount', {
                  required: !buyAmountValue,
                  onChange: () => handleAmountChange('sellAmount'),
                  min: {
                    value: sellCurrency?.minAmount?.value || 0, //
                    message: translateWithValues('sellAmountMin', {
                      minAmount: sellCurrency?.minAmount?.value + ' ' + sellCurrency?.isocode,
                    }),
                  },
                  ...(sellCurrency?.maxAmount?.value &&
                    sellCurrency?.maxAmount?.value > 0 && {
                      max: {
                        value: sellCurrency.maxAmount.value,
                        message: translateWithValues('sellAmountMax', {
                          maxAmount: sellCurrency?.maxAmount.value + ' ' + sellCurrency?.isocode,
                        }),
                      },
                    }),
                  pattern: {
                    value: RegExp(
                      `^\\d+(\\.\\d{${buyCurrency?.amountPrecision && buyCurrency?.amountPrecision > 0 ? 1 : 0},${buyCurrency?.amountPrecision}})?$`,
                    ),
                    message: translateWithValues('amountValidation', {
                      validationValue: sellCurrency?.amountPrecision,
                    }),
                  },
                })}
                onKeyDown={(e) => handleDecimalInput(e, sellCurrency?.amountPrecision)}
                error={errors.sellAmount}
                label='sellAmount'
                placeholder='amount'
                className='w-full'
                isEditMode={true}
                isRequired={!buyAmountValue}
                disabled={Boolean(buyAmountValue)}
                value={sellAmountValue}
              />
            </div>
          </div>
          <div className='text-center mt-2'>
            <Button
              loading={isValid && isLoading}
              disabled={isLoading}
              severity='info'
              onClick={() => {
                if (!isValid) {
                  showErrorToast();
                  return;
                }
              }}
            >
              <Translate value='requestQuickQuote' />
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Dashboard;
